import React from 'react'

const s = (props) => {
    return (
        <>
            <div style={{height:'40px'}}></div>
            <footer style={{ position: 'fixed', zIndex: '4', backgroundColor: 'black', color: 'white', bottom: '0', height: '30px', width: '100%', textAlign:'center', paddingTop:'0.2%' }}>
                © 2024 Ayush. All Rights Reserved.
            </footer>
        </>
    )
}

export default s
